import { Component, Input } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { Task } from '@app/models/tasks/task.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-deprecated-task-form',
    templateUrl: './deprecated-task.template.html',
    styleUrls: ['./deprecated-task.style.scss'],
})
export class DeprecatedTaskForm extends BaseForm {
    @Input() employee: Employee;
    @Input() task: Task = new Task();

    assigneeType: string = null;

    employeeTerminationDate: Date;

    isAssignedToCurrentEmployee(): boolean {
        return this.employee?.id === this.task.assigneeId;
    }

    resetIsAttachmentRequired(): void {
        if (this.isAssignedToCurrentEmployee()) {
            return;
        }

        this.task.isAttachmentRequired = false;
    }

    disableFutureDates(): void {
        if (this.employee.lastDayOfWorkOn && this.isAssignedToCurrentEmployee()) {
            this.employeeTerminationDate = new Date(this.employee.lastDayOfWorkOn);
            this.employeeTerminationDate.setDate(this.employeeTerminationDate.getDate() + 1);
        }
    }

    resetDisabledDates(): void {
        if (!this.isAssignedToCurrentEmployee()) {
            this.employeeTerminationDate = null;
        }
    }
}
