<form autocomplete="nope" #form="ngForm">
    <div class="app-grid">
        <mat-form-field class="columns-11 small-columns-12 template-fields">
            <mat-label>Name</mat-label>
            <input type="text" [(ngModel)]="task.name" name="name" #name="ngModel" required matInput />

            <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-11 small-columns-12 template-fields">
            <mat-label>Description</mat-label>
            <ui-rich-text name="description" #description="ngModel" [(ngModel)]="task.description"></ui-rich-text>
            <mat-error *ngIf="description.invalid">{{ description.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-11 small-columns-12 template-fields">
            <mat-label>Assigned to</mat-label>
            <ui-employee-autocomplete
                name="assignedTo"
                [allowNone]="true"
                [(ngModel)]="task.assignee"
                (ngModelChange)="resetIsAttachmentRequired(); disableFutureDates(); resetDisabledDates()"
                placeholder="Unassigned"
                #assignedTo="ngModel"
                [required]="true"
            ></ui-employee-autocomplete>
            <mat-error *ngIf="assignedTo.invalid">{{ assignedTo.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-checkbox
            class="columns-2 template-fields"
            *ngIf="isAssignedToCurrentEmployee()"
            name="isAttachmentRequired"
            [(ngModel)]="task.isAttachmentRequired"
        >
            File attachment required
        </mat-checkbox>

        <mat-form-field class="columns-11 small-columns-12 template-fields">
            <mat-label>Due date</mat-label>
            <input
                matInput
                name="dueAt"
                [matDatepicker]="picker"
                [max]="employeeTerminationDate"
                #dueAt="ngModel"
                [(ngModel)]="task.dueAt"
            />
            <mat-datepicker-toggle matSuffix [for]="picker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="dueAt.hasError('matDatepickerMax')"
                >Offboarding employee's due date cannot be after their last day of work.</mat-error
            >
        </mat-form-field>
    </div>
</form>
